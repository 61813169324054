<template>
  <el-dialog
    :visible.sync="dialogVisible"
    title="设置结业考试"
    top="1%"
    width="90%"
    :center="true"
    :before-close="dialogClose"
  >
    <div class="CourseTableBox">
      <section class="paperMation">
        <h3>考试设置</h3>
        <div class="paperMationDetail">
          <div title="试卷分数" class="testTime">
            <span
              class="itemLabel"
              style="display: inline-block; word-spacing: 5.25px"
              >试卷分数 ：</span
            >
            <span style="color: red; font-size: 26px">{{
              fullScore
            }}</span>
          </div>
           <div title="试卷名称" class="testTime">
              <span class="itemLabel">试卷名称 ：</span>
               <el-input
                v-model="paperName"
                type="text"
                size="small"
                placeholder="试卷名称"
              />
            </div>
          <div>
            <div title="通过分数" class="testTime">
              <span
                class="itemLabel"
                style="display: inline-block; word-spacing: 5.25px"
                >通过分数 ：</span
              >
              <el-input
                v-model="passScore"
                type="text"
                size="small"
                placeholder="请输入通过分数"
              />
            </div>
            <div title="统一考试时间" class="testTime">
              <span class="itemLabel">统一考试时间 ：</span>
              <el-date-picker
                v-model="value2"
                type="datetimerange"
                align="right"
                start-placeholder="开始日期"
                end-placeholder="结束日期"
                :default-time="['00:00:00', '23:59:59']"
                value-format="yyyy-MM-dd HH:mm:ss"
                size="small"
              ></el-date-picker>
            </div>
            <div>
              <p style="color: #d82a41; margin-top: 10px" class="testFomation">
                如果需要学员统一时间进行考试请在此设置
              </p>
            </div>
          </div>
        </div>
      </section>
      <section class="CourseTable">
        <div class="operationControl">
          <div class="searchbox" style="padding: 0">
            <div title="试卷名称" class="searchboxItem ci-full">
              <span class="itemLabel">试卷名称:</span>
              <el-input
                v-model="kpointName"
                type="text"
                size="small"
                placeholder="试卷名称"
              />
            </div>
            <span title="培训类型" class="searchboxItem ci-full flexcc">
              <span class="itemLabel">培训类型:</span>
              <el-cascader
                v-model="traintype"
                :options="TrainTypeList"
                clearable
                filterable
                :show-all-levels="false"
                :props="{
                  emitPath: false,
                  value: 'id',
                  label: 'label',
                  children: 'children',
                  checkStrictly: true,
                }"
                size="small"
              ></el-cascader>
             
            </span>
            <div>
              <el-button class="bgc-bv" @click="getData()">查询</el-button>
            </div>
          </div>
        </div>
        <div class="framePage-scroll">
          <div class="ovy-a">
            <el-table
              ref="multipleTable"
              :data="tableData"
              :height="tableHeight"
              size="small"
              tooltip-effect="dark"
              style="width: 100%"
              :header-cell-style="tableHeader4"
            >
              <el-table-column label width="50px" align="center" fixed>
                <template slot-scope="scope">
                  <el-radio
                    v-model="radio"
                    :label="scope.row.paperId"
                    @change="radioChange(scope.row)"
                    :checked="radio == scope.row.paperId ? true : false"
                  />
                </template>
              </el-table-column>
              <el-table-column
                label="试卷名称"
                align="center"
                show-overflow-tooltip
                prop="paperName"
                minWidth="260"
                fixed
              />
              <el-table-column
                label="培训类型"
                align="center"
                show-overflow-tooltip
                prop="trainTypeNamePath"
                width="120"
              >
                <template slot-scope="scope">
                  {{ scope.row.trainTypeNamePath || "--" }}
                </template>
              </el-table-column>
              <el-table-column label="课程来源" align="center">
                <template slot-scope="scope">
                  <span>
                    {{ $setDictionary("PAPERORGIN", scope.row.paperOrgin) }}
                  </span>
                </template>
              </el-table-column>
              <el-table-column label="操作" align="center">
                <template slot-scope="scope">
                  <el-button
                    type="text"
                    style="padding: 0px 15px"
                    size="mini"
                    @click="showPaper(scope.row.paperId)"
                    >预览</el-button
                  >
                </template>
              </el-table-column>
              <Empty slot="empty" />
            </el-table>
          </div>
        </div>
        <PageNum
          :apiData="apiData"
          @sizeChange="sizeChange"
          @getData="getData"
        />
      </section>
      
    </div>
    <span class="dialog-footer">
      <div class="dialogBtn">
        <el-button @click="dialogClose">取消</el-button>
        <el-button class="bgc-bv" @click="doOk">确定</el-button>
      </div>
    </span>
    <PaperShow ref="PaperShow" :modal="false" />
  </el-dialog>
</template>
<script>
import Empty from "@/components/Empty.vue";
import PageNum from "@/components/PageNum.vue";
import List from "@/mixins/List";
import PaperShow from "@/views/resourse/popup/PaperShow";
import { mapGetters } from "vuex";

export default {
  name: "course",
  components: {
    Empty,
    PageNum,
    PaperShow,
  },
  mixins: [List],
  data() {
    return {
      value2: [],
      dialogVisible: false,
      kpointName: "",
      traintype: "",
      TrainTypeList:[],
      courseOrigin: "",
      courseOriginList: [],
      passScore: "",
      passoldfraction: "",
      radio: "",
      props: {
        value: "id",
        label: "label",
      },
      projectCourseId: "",
      paperName: "",
      paperId: "",
      fullScore: 0,
      ruleForm: {
        Trainingtype: "",
      },
    };
  },

  computed: {
    ...mapGetters({
      traintypeList: "getTraintype",
    }),
  },
  methods: {
    tinit() {
      this.getCourseOriginList();
      this.getTrainTypeArrData()
      this.init();
      this.getTableHeight();
    },
    getCourseOriginList() {
      const courseOriginList = this.$setDictionary("COURSEORGIN", "list");
      const list = [];
      for (const key in courseOriginList) {
        list.push({
          value: key,
          label: courseOriginList[key],
        });
      }
      this.courseOriginList = list;
    },
    //获取培训类型数据
    getTrainTypeArrData() {
      this.$post("/sys/category/train-type/enterprise", {}).then((ret) => {
        this.TrainTypeList = ret.data;
      });
    },
    getData(pageNum = 1) {
      const params = {
        pageNum: pageNum,
        pageSize: this.pageSize,
        paperName: this.kpointName || "",
        trainTypeId:this.traintype || "",
        auditState: 30,
        paperShowType:'10',
      };
      this.doFetch({
        url: "/biz/et/paper/pageList",
        params,
        pageNum,
      });
    },
    getTableHeight(opDom = true, page = true) {
      let tHeight = 760 - (3 + 1.35 + 3.25 + 0.675 + 2 + 1.35 + 3.5) * 16;
      if (opDom) {
        tHeight -= 0.675 * 16 + 1;
      }
      if (page) {
        tHeight -= 32;
      }
      this.tableHeight = tHeight;
    },
    radioChange(row) {
      this.fullScore = row.fullScore;
      this.passScore = row.passScore;
      this.paperScore = row.paperScore;
      this.radio = row.paperId;
      this.paperName = row.paperName;
      this.value2[0] = row.paperStartTime || this.value2[0];
      this.value2[1] = row.paperEndTime || this.value2[1];
    },
    doOk() {
      if (!this.radio) {
        this.$message({
          message: "请选择试卷",
          type: "warning",
        });
        return false;
      } else {
        if (Number(this.passScore) > Number(this.fullScore)) {
          this.$message({
            message: "通过分数不应大于试卷总分数",
            type: "warning",
          });
          return false;
        }
        //判断分数是否不等于100
        if (
          Number(this.fullScore) > 100 ||
          Number(this.fullScore) < 100
        ) {
          this.$confirm(
            "您选择的试卷总分为" + '<span style="color:red;font-size:26px">'+this.fullScore + '</span>' + "分,确认使用?",
            "提示",
            {
              confirmButtonText: "确定",
              cancelButtonText: "取消",
              type: "warning",
                 dangerouslyUseHTMLString:true,
              closeOnClickModal: false,
            }
          )
            .then(() => {
              const parmar = {
                paperId: this.radio || this.paperId,
                paperName: this.paperName || this.paperName,
                paperScore: this.passScore
                  ? this.passScore
                  : this.paperScore,
                projectCourseId: this.projectCourseId,
                // paperStartTime:'',
                // paperEndTime:''
              };
              if (this.value2) {
                (parmar.paperStartTime = this.value2[0]),
                  (parmar.paperEndTime = this.value2[1]);
              }
              this.$post("/biz/et/trainTask/course/configureProjectCourseAddPaper", parmar)
                .then((ret) => {
                  this.$message({
                    message: "关联成功",
                    type: "success",
                  });
                  this.dialogClose();
                  this.$emit("eventBus");
                  this.$emit("getData");
                  this.$emit("getDatasall");
                  this.passScore = "";
                  this.value2 = [];
                })
                .then((err) => {
                  return;
                });
            })
            .catch(() => {
              return;
            });
        } else {
          const parmar = {
            paperId: this.radio || this.paperId,
            paperName: this.paperName || this.paperName,
            paperScore: this.passScore ? this.passScore : this.paperScore,
            projectCourseId: this.projectCourseId,
            // paperStartTime:'',
            // paperEndTime:''
          };
          if (this.value2) {
            (parmar.paperStartTime = this.value2[0]),
              (parmar.paperEndTime = this.value2[1]);
          }
          this.$post("/biz/et/trainTask/course/configureProjectCourseAddPaper", parmar)
            .then((ret) => {
              this.$message({
                message: "关联成功",
                type: "success",
              });
              this.dialogClose();
              this.$emit("eventBus");
              this.$emit("getData");
              this.$emit("getDatasall");
              this.passScore = "";
              this.value2 = [];
            })
            .then((err) => {
              return;
            });
        }
      }
    },
    dialogClose() {
      this.dialogVisible = false;
      this.kpointName = "";
      this.ruleForm.Trainingtype = "";
      // this.$emit("eventBus");
      this.value2 = [];
    },
    showPopUp(
      fullScore,
      projectCourseId,
      paperScore,
      paperName,
      paperId,
      paperStartTime,
      paperEndTime
    ) {
      console.log(fullScore);
      this.dialogVisible = true;
      this.fullScore = fullScore;
      this.projectCourseId = projectCourseId;
      this.passScore = paperScore;
      this.paperName = paperName;
      if (paperStartTime) {
        this.value2 = [
          this.$moment(paperStartTime).format("YYYY-MM-DD HH:mm:ss"),
          this.$moment(paperEndTime).format("YYYY-MM-DD HH:mm:ss"),
        ];
      }

      //this.kpointName = paperName;
      this.paperId = paperId;
      this.radio = paperId;
      this.getData(1);
    },
    showPaper(paperId) {
      this.$refs.PaperShow.showPopUp(paperId); // 打开弹窗预览试题
    },
  },
};
</script>
<style lang="less">
.CourseTableBox {
  display: flex;
  justify-content: space-between;
  height: 600px;
  .paperMation {
    width: 35%;
    border: 1px solid #5c6be8;
    padding: 20px;
    border-radius: 0.5rem;
    .paperMationDetail {
      padding: 20px 0 0 0;
    }
  }
}
.CourseTable {
  width: 63%;
  border: 1px solid #5c6be8;
  padding: 10px;
  border-radius: 0.5rem;
  .el-radio {
    .el-radio__label {
      display: none;
    }
  }
}
.testTime {
  // margin-left: 180px;
  padding-top: 20px;
  .itemLabel {
    display: inline-block;
    width: 130px;
    padding-bottom: 0.75rem;
    line-height: 2;
  }
  .testFomation {
    display: block;
    width: 300px;
  }
}
.dialog-footer {
  display: flex;
  justify-content: center;
  margin-top: 1rem;
}
.dialogBtn {
  button {
    width: 10rem;
  }
}
</style>
<style lang="less" scoped>

//人力资源样式重置
/deep/::-webkit-scrollbar-thumb{
background-color: #eee;
border-radius: 0px;
}
/deep/::-webkit-scrollbar-track-piece {
background: #fff;
border-radius: 0px;
}
/deep/.el-pager li{
background-color: #fff;
border: 1px solid #d9d9d9;
}
/deep/.el-pagination.is-background .el-pager li{
background-color: #fff;
}
/deep/.el-pagination.is-background .el-pager li:not(.disabled).active{
background-color: #2878FF;
border: 1px solid #2878FF;
}
/deep/.el-pagination.is-background .btn-next, /deep/.el-pagination.is-background .btn-prev{
border: 1px solid #d9d9d9;
background-color: #fff;
}
/deep/.el-pager li.active+li{
border-left: 1px solid #d9d9d9;
}
/deep/.el-pagination.is-background .btn-next.disabled, /deep/.el-pagination.is-background .btn-next:disabled, /deep/.el-pagination.is-background /deep/.btn-prev.disabled, /deep/.el-pagination.is-background .btn-prev:disabled, /deep/.el-pagination.is-background .el-pager li.disabled {
color: #C0C4CC;
background-color: #f4f4f5;
border: 1px solid #d9d9d9;
}
/deep/.el-pagination button{
border: 1px solid #d9d9d9;
}
/deep/.el-button--text{
//color: #2878FF;
font-size: 14px!important;
}
/deep/.bgc-bv{
background-color: #2878FF !important;
color: #fff !important;
border-color: #2878FF !important;
border-radius: 0;
}
.el-button.is-disabled, .el-button.is-disabled:focus, .el-button.is-disabled:hover{

}
/deep/.el-table th.el-table__cell>.cell{
font-size: 14px!important;
font-weight: 400!important;
}
/deep/.el-dialog__header{
background-color: #fff;
}
/deep/.el-dialog__header .el-dialog__title{
color: #333;
}
/deep/.el-dialog__header .el-icon-close:before{
color: #333;
}
/deep/.el-range-editor.is-active, /deep/.el-range-editor.is-active:hover, /deep/.el-select .el-input.is-focus .el-input__inner{
border-color: #2878FF !important;
}
.CourseTable{
  border-color: #2878FF !important;
}
.CourseTableBox .paperMation{
  border-color: #2878FF !important;
}
</style>