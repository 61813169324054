<template>
  <div class="pageContol listWrap templateList">
    <div class="framePage">
      <div class="framePage-title">
        <span>
          <em>当前位置：</em>
          <a href="javascript:;" @click="goHerf()">培训管理</a>
          <i>></i>
          <a href="javascript:;" @click="init" class="cur-a"
            >{{ projectName }}-详情</a
          >
        </span>
      </div>
      <div class="framePage-body framePage-tabs">
        <el-tabs
          v-model="activeName"
          :before-leave="beforeTabLeave"
          @tab-click="tabChange"
        >
          <el-tab-pane label="课程列表" name="second">
            <CourseList ref="second" :projectId="projectId" v-if="activeName=='second'" />
          </el-tab-pane>
          <el-tab-pane label="学员列表" name="three">
            <!-- 普通学员列表 -->
            <StudentList ref="three" :projectId="projectId" @tabChange="tabChange" v-if="activeName=='three'" />
          </el-tab-pane>
        </el-tabs>
      </div>
    </div>
  </div>
</template>
<script>
import List from "@/mixins/List";
import CourseList from "@/views/humanResources/trainingManagement/CourseList.vue";
import StudentList from "@/views/humanResources/trainingManagement/StudentList.vue";
import { mapGetters } from "vuex";
export default {
  name: "ClassdetailsList",
  components: {
    CourseList,
    StudentList,
  },
  mixins: [List],
  data() {
    return {
      activeName: "",
      projectName: "",
    };
  },
  mounted() {
    this.init()
  },
  watch: {
    
  },
  computed: {
    ...mapGetters({
      userJson: "getUser",
    }),
  },
  methods: {
    init() {
      const query = this.$route.query;
      if (query.active) {
        this.activeName = query.active;
        this.projectId = query.projectId;
        this.projectName = query.projectName;
      }
    },
    tabChange(tab) {
      this.activeName = tab.name;
    },
    beforeTabLeave(newName) {
      // this.$router.replace({
      //   path: this.$route.path,
      //   query: { active: newName },
      // });
    },
    goHerf() {
      this.$router.back();
    },
  },
};
</script>
<style lang="less" scoped>
.framePage-tabs {
  flex: 1;
  flex-grow: 1;
  flex-shrink: 1;
  flex-basis: 0%;
  display: flex;
  flex-direction: row;
  overflow: hidden;
  position: relative;
  background-color: #fff;
  padding: 1rem 0.675rem;
  box-sizing: border-box;
}
#pane-first {
  display: flex;
  flex: 1;
  overflow: hidden;
}
</style>
<style lang="less">
.framePage-tabs {
  .el-tabs {
    width: 100%;
  }
  .el-tabs__content {
    overflow-y: auto;
  }
}

//人力资源样式重置
/deep/::-webkit-scrollbar-thumb{
  background-color: #eee;
  border-radius: 0px;
}
/deep/::-webkit-scrollbar-track-piece {
  background: #fff;
  border-radius: 0px;
}
/deep/.el-pager li{
  background-color: #fff;
  border: 1px solid #d9d9d9;
}
/deep/.el-pagination.is-background .el-pager li{
  background-color: #fff;
}
/deep/.el-pagination.is-background .el-pager li:not(.disabled).active{
  background-color: #2878FF;
  border: 1px solid #2878FF;
}
/deep/.el-pagination.is-background .btn-next, /deep/.el-pagination.is-background .btn-prev{
  border: 1px solid #d9d9d9;
  background-color: #fff;
}
/deep/.el-pager li.active+li{
  border-left: 1px solid #d9d9d9;
}
/deep/.el-pagination.is-background .btn-next.disabled, /deep/.el-pagination.is-background .btn-next:disabled, /deep/.el-pagination.is-background /deep/.btn-prev.disabled, /deep/.el-pagination.is-background .btn-prev:disabled, /deep/.el-pagination.is-background .el-pager li.disabled {
  color: #C0C4CC;
  background-color: #f4f4f5;
  border: 1px solid #d9d9d9;
}
/deep/.el-pagination button{
  border: 1px solid #d9d9d9;
}
/deep/.el-button--text{
  //color: #2878FF;
  font-size: 14px!important;
}
/deep/.bgc-bv{
  background-color: #2878FF !important;
  color: #fff !important;
  border-color: #2878FF !important;
  border-radius: 0;
}
/deep/.el-table th.el-table__cell>.cell{
  font-size: 14px!important;
  font-weight: 400!important;
}
/deep/.el-dialog__header{
  background-color: #fff;
}
/deep/.el-dialog__header .el-dialog__title{
  color: #333;
}
/deep/.el-dialog__header .el-icon-close:before{
  color: #333;
}
/deep/.el-range-editor.is-active, /deep/.el-range-editor.is-active:hover, /deep/.el-select .el-input.is-focus .el-input__inner{
  border-color: #2878FF;
}
</style>
